<template>
	<div class="product" v-show="!menuActive">
		<div class="product__heading mb-5">
			<div class="labels mb-3">
				<div class="type-label">{{ typeLabel }}</div>
				<div class="product-label">{{ productLabel }}</div>
			</div>
			<div class="profiles mb-3">
				<div class="image" v-for="( image, idx ) in singleProduct.profiles" :key="idx">
					<LabelImage
						v-if="$image(image) && !$image(image).includes('data:image/')"
						:data="{image, id: `image__${productId}__${idx}`}"
						label-position="before"
						img-height="70px"
						:contained="true"
					/>
				</div>
			</div>
			<div class="brand-logo">
				<img :src="$image( getBrandLogo, {alt:true, extension:'png', folder: 'brands'} )" :alt="getBrandLogo">
			</div>
		</div>
		<!-- <InfoWithGallery
			:image="categories[singleProduct.image].image"
			:title="labelGetter(singleProduct.label)"
			:categories="categoryList"
			:brand="getBrand"
		/>
		<hr> -->
		<template>

			<h4 v-if="!Object.keys(dropdownList).length"> {{ sysLabelGetter('this_product_have_no_categories') }}</h4>

			<div
				class="parts"
				v-for="(group, idx) in partsListGroups"
				:key="idx"
				:style="{ '--preview-z-index': partsListGroups.length - idx }"
			>

				<div class="parts-wrapper row" :id="group.categoryId">
					<div class="parts__category-preview col">
						<LabelImage
							class="mb-3"
							v-if="$route.name === 'category' && group.categoryId"
							label-position="before"
							extension="jpg"
							:data="labelImageData(group.categoryId)"
							:contained="true"
						/>

						<!-- <FormulateInput
							type="select"
							class="categories-dropdown mb-5 mb-sm-0"
							v-model="selectedCategory"
							:options="dropdownList"
							:placeholder="sysLabelGetter('area')"
							@input="openCategory"
							@click="scrollToCategory"
						/> -->
						<CustomDropdown
							class="categories-dropdown mb-5 mb-sm-0"
							:id="`anchor-dropdown-${group.categoryId}`"
							:value="selectedCategory"
							:options="dropdownList"
							@input="scrollToCategory"
						/>
					</div>

					<div class="parts__list col" v-if="group.parts && group.parts.length">
						<OrderInfo
							v-for="(item, index) of group.parts"
							:key="index"
							:data="item"
							:category-id="group.categoryId"
							:identifier="`idx-${idx}__category-${group.categoryId}__idx-${index}__part-${item.id}`"
						/>
					</div>

					<h4 v-else-if="$route.name === 'category'">{{ sysLabelGetter('this_category_have_no_parts') }}</h4>
				</div>

				<hr class="divider">
			</div>

			<LabelList :list="currentProductList" v-if="Object.keys(currentProductList).length" class="mt-5"/>

		</template>
	</div>
</template>

<script>
import LabelList from '@/components/LabelList.vue'
import LabelImage from '@/components/LabelImage.vue';
import OrderInfo from '@/components/OrderInfo.vue';
import CustomDropdown from '@/components/CustomDropdown.vue';

import { mapState, mapGetters } from 'vuex';

export default {
	name: 'Product',
	components: {
		LabelList,
		LabelImage,
		OrderInfo,
		CustomDropdown,
	},
	data() {
		return {
			selectedCategory: '',
		}
	},
	computed: {
		...mapState( 'main', {
			menuActive: "menuActive",
			activeBrandLabel: 'activeBrandLabel',
			structure: 'structure',
			products: 'products',
			brands: 'brands',
			parts: 'parts',
			types: 'types',
			categories: 'categories',
		} ),
        ...mapGetters("main", {
            labelGetter:'labelGetter',
            sysLabelGetter:'sysLabelGetter',
        }),
		// getBrand() {
		// 	let output = '';
		// 	if ( this.labelGetter(this.brands[this.singleProduct.brand].label) ) {
		// 		output = this.labelGetter(this.brands[this.singleProduct.brand].label);
		// 	}
		// 	return output;
		// },
		getBrandLogo() {
			return this.activeBrandLabel.split(' ').join('-').toLowerCase();
		},
		singleProduct() {
			return this.products[this.productId]
		},
		productId() {
			let productId = this.$route.params.id;
			// if ( this.$route.name === 'category' ) productId = this.categories[this.$route.params.id].product;
			return productId;
		},
		productLabel() {
			if ( this.singleProduct && this.singleProduct.label !== undefined ) {
				return this.labelGetter(this.singleProduct.label, `product ${this.productId}`);
			}
			return '';
		},
		typeLabel() {
			if (
				this.types &&
				this.singleProduct &&
				this.singleProduct.type &&
				this.types[this.singleProduct.type].label !== undefined
			) {
				return this.labelGetter(this.types[this.singleProduct.type].label, `product ${this.productId}`);
			}
			return '';
		},
		// categoryList() {
		// 	let output = {};
		// 	if ( this.products[this.productId].images ) {
		// 		let categoryIdList = this.products[this.productId].images;
		// 		categoryIdList.forEach( id => {
		// 			let obj = JSON.parse( JSON.stringify( this.categories[id] ) );
		// 			obj.label = this.labelGetter(obj.label);
		// 			obj.id = id;
		// 			output[id] = obj;
		// 		} )
		// 	}
		// 	return output
		// },
		partsIdList() {
			return this.structure[this.singleProduct.brand][this.singleProduct.type][this.productId];
		},
		dropdownList() {
			let output = {};
			if (this.singleProduct.categories) {
				let categoriesIdList = this.singleProduct.categories;
				categoriesIdList.forEach( categoryId => {
					if (
						this.categories[categoryId] &&
						this.categories[categoryId].label !== undefined
					) {
						output[categoryId] = this.labelGetter(this.categories[categoryId].label, `category ${categoryId}`);
					}
				} )
				let id = Object.keys( output )[0];
				if ( this.$route.name === 'category' ) this.setSelectedCategory( this.$route.params.catId );
				else if ( this.$route.name === 'product' && Object.keys( output ).length ) {
					this.setSelectedCategory( id );
					this.openCategory( id );
				}
			}
			return output;
		},
		currentProductList() {
			// console.time('Execution time');
			let output = {};
			let productIds = [];
			this.partsListGroups.forEach(group => {
				group.parts.forEach( item => {
					// productIds = [...new Set([...productIds , ...item.products])];
					productIds = Array.from( new Set( productIds.concat(item.products) ) );
				} )
			})
			productIds.forEach( id => {
				let product = this.products[id];
				output[id] = {
					_id: product._id,
					label: this.labelGetter(product.label, `product ${id}`),
					link: { name: 'product', params: { id } }
				};
			} )
			// console.timeEnd('Execution time');
			return output;
		},
		partsListGroups() {
			let output = [];
			if ( this.$route.name === 'product' ) {
				output.push(this.getPartList( this.partsIdList, null ))
			}
			if ( this.$route.name === 'category' && this.singleProduct.categories ) {
				this.singleProduct.categories.forEach(categoryId => {
					if ( this.categories[categoryId].parts ) {
						output.push(this.getPartList( this.categories[categoryId].parts, categoryId ))
					}
				})
			}
			return output;
		},
	},
	methods: {
		getPartList(partsIdList, categoryId) {
			let output = {
				categoryId,
				parts: []
			}
			partsIdList.forEach( item => {
				let data = JSON.parse(JSON.stringify(this.parts[item]));
				let obj = {
					id: item,
					...data,
					footnote: data.footnote ? this.labelGetter(data.footnote, `footnote of part ${item}`) : '',
					label: data.label ? this.labelGetter(data.label, `part ${item}`) : ''
				}
				if ( this.$route.name === 'product' ) delete obj.position;
				output.parts.push( obj );
			} )
			return output
		},
		scrollToCategory(id) {
			const element = document.getElementById(id)
			// element.scrollIntoView({block: "start", behavior: "smooth"});
			const yOffset = -200;
			const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
			window.scrollTo({top: y, behavior: 'smooth'});

			this.setSelectedCategory( id )
			this.openCategory( id )
		},
		openCategory( id ) {
			if ( this.$route.name !== 'category' || this.$route.params.catId !== id ) {
				this.$router.push( { name: 'category', params: { catId: id } } )
			}
		},
		setSelectedCategory( id ) {
			this.selectedCategory = id;
		},
		labelImageData(categoryId) {
			let output = {};
			if ( this.$route.name === 'category' ) {
				output.label = this.categories[categoryId].label ? this.labelGetter(this.categories[categoryId].label, `category ${categoryId}`) : '';
				output.image = this.categories[categoryId].image;
				output.id = categoryId;
			}
			return output;
		},
	},
	watch: {
		$route( to,from ) {
			if ( to.params.id !== from.params.id ) this.selectedCategory = '';
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.product {

	&__heading {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		gap: 1rem;

		.labels {
			font-weight: 700;

			.type-label {
				font-size: .75rem;
			}

			.product-label {
				font-size: 1.75rem;
			}
		}

		.profiles {
			display: flex;
			gap: 5px;
			flex: 1 1 auto;
		}

		.brand-logo {
			width: 150px;

			img{
				width: 100%;
			}
		}
	}

	.parts {

		.parts-wrapper {
			position: relative;
			flex-direction: column;
		}

		.categories-dropdown {
			max-width: 200px;
		}

		.divider {
			margin-top: 3rem;
			margin-bottom: 3rem;
			color: $border_color;
		}
	}

	@media(min-width: 568px) {
		.parts {
			.parts-wrapper {
				flex-direction: row;
			}

			&__category-preview {
				flex: 0 0 230px;
				max-width: 230px;
				height: max-content;
				position: sticky;
				z-index: var(--preview-z-index);
				top: 200px;
			}

			&__list {
				flex: 1 1 auto;
				max-width: calc( 100% - 230px )
			}
		}
	}
}

</style>