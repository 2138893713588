<template>
    <div class="custom-dropdown" :id="id" :style="style">
        <div
            :class="['custom-dropdown__header', { active: opened }]"
            :id="`custom-dropdown__header__${id}`"
            @click="openDropdown"
        >
            {{options[value]}}
            <font-awesome-icon icon="caret-down" />
        </div>

        <div class="custom-dropdown__body" :id="`custom-dropdown__body__${id}`" v-show="opened">
            <div class="option"
                v-for="(option, name) in options"
                :key="name"
                :value="option"
                :selected="value === name"
                @click="changeValue(name)"
            >
                {{ option }}
            </div>
        </div>
    </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
library.add(faCaretDown);

import { mapState } from "vuex";

export default {
    name: "CustomDropdown",
    props: {
        value: {
            type: String,
            default: ''
        },
        options: {
            type: Object
        },
        id: {
            type: String,
            default: 'custom-dropdown'
        },
    },
    data() {
        return {
            opened: false
        }
    },
    computed: {
        ...mapState("main", {
            selectedBrandLabel: "selectedBrandLabel",
        }),
        style() {
            return this.$getBrandColor(this.selectedBrandLabel);
        },
    },
    methods: {
        changeValue(value) {
            this.opened = false;
            this.$emit('input', value)
        },
        openDropdown() {
            this.opened = !this.opened

            const header_element = document.getElementById(`custom-dropdown__header__${this.id}`)
            const body_element = document.getElementById(`custom-dropdown__body__${this.id}`)
            const header_element_rect = header_element.getBoundingClientRect()

            if (window.outerHeight - header_element_rect.bottom < 190) {
                if (window.outerHeight - header_element_rect.bottom < header_element_rect.top) {
                    body_element.style.top = 'unset'
                    body_element.style.bottom = header_element.offsetHeight + 'px'
                } else {
                    body_element.style.top = '100%'
                    body_element.style.bottom = 'unset'
                }
            } else {
                body_element.style.top = '100%'
                body_element.style.bottom = 'unset'
            }
        },
        eventListener( e ) {
            if ( this.opened ) {
                let element = document.getElementById(this.id);

                if ( element && !element.contains(e.target) ) this.opened = false;
            }
        }
    },
    mounted() {
        document.addEventListener('click', this.eventListener );

        document.addEventListener('scroll', this.eventListener );
    },
    beforeDestroy() {
        document.removeEventListener('click', this.eventListener );

        document.removeEventListener('scroll', this.eventListener );
    },
}
</script>

<style lang="scss" scoped>

.custom-dropdown {
    position: relative;
    cursor: pointer;
    font-size: .875rem;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $border_color;
        border-radius: 5px;
        padding: 10px 15px 10px 10px;
        font-size: .875rem;
        @include transition--ease-out;

        &.active {
            border-color: var(--brand-color);
        }

        svg {
            color: #cecece;
        }
    }

    &__body {
        position: absolute;
        top: 100%;
        border-radius: 5px;
        border: 1px solid $border_color;
        background-color: #fff;
        width: 100%;
        height: auto;
        max-height: 190px;
        overflow-x: hidden;
        overflow-y: auto;
        z-index: 1001;

        &::-webkit-scrollbar {
            width: $scrollbar_width;
        }

        &::-webkit-scrollbar-track {
            background: $border_color;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--brand-color);
        }

        .option {
            padding: 3px 10px;
            a {
                text-decoration: none;
                color: $light_text;
            }
        }
    }
}

</style>